// import { Button } from "antd";
// import React, { useEffect } from "react"
// import { useTranslation } from "react-i18next"


// const LanguageSelector = () => {
  
//   const { i18n } = useTranslation();
//   const languages = [
//     { code: 'en', name: 'English' },
//     { code:'ar', name: 'Arabic' }
//   ]

//   const changeLanguage = (lng) => { 
//     i18n.changeLanguage(lng)
//   }

//   useEffect(() => {
//     console.log(i18n.dir());
//     document.body.dir = i18n.dir()
//   },[i18n,i18n.language])

//   return (
//     <div >
//       {languages.map(lang => {
//         return (
//           <Button key={lang.code} onClick={()=>changeLanguage(lang.code)}>{lang.name}</Button>
//         );
//       })}
//     </div>
//   )
// }

// export default LanguageSelector;

import { Button, Dropdown, Menu } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './Css/LanguageDropdown.css'
import languageIcon from './../assets/globe.png'

const LanguageSelector = ({setSelectedLang}) => {
  
  const { i18n } = useTranslation();
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' }
  ];

  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng);
    setSelectedLang(lng)
    sessionStorage.setItem('selectedLang',lng)
    console.log("lng", lng)
  };

  useEffect(() => {
    console.log(i18n.dir());
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const menu = (
    <Menu>
      {languages.map(lang => (
        <Menu.Item key={lang.code} onClick={() => changeLanguage(lang.code)}>
          {lang.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
<div className="wrapperPad">
  {
    languages.map(lang => (
      lang.code === localStorage.getItem('i18nextLng') ? (
        <Dropdown overlay={menu} placement="bottomLeft" className="customDropdown" key={lang.code}>
          <Button>
            {/* <img className="languageIcon" src={languageIcon} alt={`${lang.name} icon`} /> */}
            {lang.name}
          </Button>
        </Dropdown>
      ) : null
    ))
  }
</div>

  );
}

export default LanguageSelector;
