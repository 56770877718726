import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import config from "../config";
import { Spin } from "antd";

const CheckSubscription = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPremium, setPremium] = useState(false);
  const [subMessage, setMessage] = useState('');
  const [invited,setIsInvited]=useState(false)

  useEffect(() => {

    checkUserAuth();

  }, []);






  const checkSubscription = async () => {
    const username = localStorage.getItem('username');
    const token=localStorage.getItem('token')

    if (username) {
      // const { username, token } = JSON.parse(userdata);
      try {
        const subscriptionResponse = await fetch(`${config.apiUrl}subscription-status/${username}/`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (subscriptionResponse.ok) {
          const subscriptionData = await subscriptionResponse.json();

          let subscription;
          if (subscriptionData.stripe_subscription) {
            subscription = subscriptionData.stripe_subscription;
          } else if (subscriptionData.shopify_subscription) {
            subscription = subscriptionData.shopify_subscription;
          }

          if (subscription && subscription.paid) {
            setPremium(true);
          } else {
            if (subscription && subscription.subscriptionStatus === "past_due") {
              setMessage("Your subscription has expired or is inactive. Please update your billing information.");
            } else {
              setMessage("Your subscription has been cancelled. Please update your billing information.");
            }
            setPremium(false);
          }
        } else {
          setPremium(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setPremium(false);
      }
    }
    setIsLoading(false);
  };
  const checkUserAuth = async () => {
    if (localStorage.getItem('invited')){
      console.log(localStorage.getItem('invited'))
      setIsInvited(true)
      setIsLoading(false);
    }
    else{
      checkSubscription();
     
    }
  };


  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!isPremium && !invited) {
    return <Navigate to="/user/billing" state={{ message: subMessage }} />;
  }

  return <Outlet />;
};

export default CheckSubscription;
