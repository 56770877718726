import React, { useState, useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import { message, Progress } from 'antd';

export default function SuccessShopify() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [progress, setProgress] = useState(0); // Initialize progress state

  useEffect(() => {
    handleSignupOnSuccessPage();
  }, []);

  // Function to handle signup on success page
  async function handleSignupOnSuccessPage() {
    try {
      setProgress(10); // Update progress
  
      const params = new URLSearchParams(window.location.search);
      const shop = params.get('shop');
      const access_token = params.get('accessToken');
      const charge_id=params.get('charge_id')
  
      if (!shop || !access_token) {
        throw new Error('Missing shop or accessToken in the URL');
      }
  
      setProgress(30);
  
      // Make the signup API request
      const response = await axios.post(`${config.apiUrl}shopifybilling/success`, { shop, access_token, charge_id }, {
        headers: {
          'Content-Type': 'application/json',
        },
        onUploadProgress: progressEvent => {
          setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100 * 0.5) + 30); 
        }
      });
  
      setProgress(60); 

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      const token = response.data.token;
      const refreshToken = response.data.refreshToken;

      // Create a JSON object containing the tokens
      const userData = {
        token: token,
        refreshToken: refreshToken,
      }
      localStorage.setItem("Userdata", JSON.stringify(userData));
      
      setIsLoggedIn(true); 
  
      setProgress(100); 
      message.success('User Account Created Successfully');
  


    } catch (error) {
      // Handle error
      console.error('Signup error:', error);
      if (error.response && error.response.data) {
        message.error('Error creating account', 2);
      }
    }
  }
  

  return (
    <div className="flex items-center justify-center h-screen">
      <div>
        <div className="flex flex-col items-center space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h1 className="text-4xl font-bold">Thank You!</h1>

          {isLoggedIn ? (
              <p>Account Creation Successful 🎉 </p>

          ) : (
            <p>Wait for Account Creation..</p>

          )}

          {isLoggedIn ? (
            <a
              className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded  hover:bg-indigo-700 focus:outline-none focus:ring"
              href="/user/chatbot">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <span className="text-sm font-medium text-white">Return to the dashboard</span>
            </a>
          ) : (
            <Progress percent={progress} size="small" />
          )}
        </div>
      </div>
    </div>
  );
}
