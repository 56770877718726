import { useState } from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

export default function Pricing() {

  const navigate =useNavigate();



  return (
    <>
    <div className="absolute top-0 right-0 p-4">
      </div>
      <div className="bg-gray-300 font-sans lg:bg-transparent flex flex-col lg:flex-row absolute justify-center lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 px-5 xl:px-0 py-8 lg:py-0 w-full gap-6 items-center lg:items-stretch">
        {PRICING_DATA.map((data, index) => (
          <div key={index} className="relative">
            <div className="max-w-sm xl:w-[384px] p-6 bg-white group h-full rounded-2xl lg:hover:-translate-y-6 ease-in duration-300 hover:bg-[#7F28F8] hover:text-white border xl:border border-[#c9c7c7]">
              <div className="flex flex-row gap-5 items-center">
                {/* Display icon here if needed */}
                <span className="text-3xl font-bold">{data.name}</span>
              </div>
              <span className="flex mt-4 text-[#A9A9AA] text-2xl">What You&apos;ll Get</span>
              {data.benefits.map((benefit, index) => (
                <div key={index} className="flex flex-row gap-2.5 items-start mt-6 text-left text-lg">
                  <div className="pt-1 shrink-0">
                    <RightIcon />
                  </div>
                  <span>{benefit}</span>
                </div>
              ))}
              <div className="border border-dashed border-[#A9A9AA] tracking-widest my-4" />
              <div className="h-36">
                <div className="bottom-6 left-6 right-6 absolute">
                  <div className="flex justify-start items-baseline">
                    <span className="text-[32px] font-bold ">{data.price}</span>
                  </div>
                  {data.name === "Enterprise Plan" && (
                    <button
                      onClick={() => {
                        window.location.href = "https://calendly.com/info-q5o/enterprise-plan";
                      }}
                      className="w-full px-4 py-3 bg-[#FFF5FA] text-[#FF1D89] group-hover:text-white group-hover:bg-[#0B0641] rounded-xl mt-6 font-semibold text-xl"
                    >
                      Book Now
                    </button>
                  )}

                {data.name === "Web Access" && (
                    <button
                      onClick={() => {
                        navigate('/auth/signup/v2');  // CHANGE REDIRECTION BEFORE PUSING

                      }}
                      className="w-full px-4 py-3 bg-[#FFF5FA] text-[#FF1D89] group-hover:text-white group-hover:bg-[#0B0641] rounded-xl mt-6 font-semibold text-xl"
                    >
                      Choose 
                    </button>
                    )}

                {data.name === "Shopify Access" && (
                    <button
                      onClick={() => {
                        window.location.href = 'https://apps.shopify.com/jawebchatbot';

                      }}
                      className="w-full px-4 py-3 bg-[#FFF5FA] text-[#FF1D89] group-hover:text-white group-hover:bg-[#0B0641] rounded-xl mt-6 font-semibold text-xl"
                    >
                      Choose 
                    </button>
                    )}

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};


  const RightIcon = () => {
    return (
      <svg
        className="fill-current"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.0001 0.00012207C4.48608 0.00012207 7.62939e-05 4.48612 7.62939e-05 10.0001C7.62939e-05 15.5141 4.48608 20.0001 10.0001 20.0001C15.5141 20.0001 20.0001 15.5141 20.0001 10.0001C20.0001 4.48612 15.5141 0.00012207 10.0001 0.00012207ZM8.00108 14.4131L4.28808 10.7081L5.70008 9.29212L7.99908 11.5871L13.2931 6.29312L14.7071 7.70712L8.00108 14.4131Z" />
      </svg>
    );
  };
  const PRICING_DATA = [
    {
      name: "Shopify Access",
      price: "$49",
    //   iconComponent: <Web />,
      benefits: [
        "Access to our Awesome Dashboard",
        "Access to Chatlogs",
        "Access to follow up feature",
        "Access to Human Interference",
        "Live Chatbot On Your Shopify Store"
       
      ],
      buttonText:"Choose Now"
    },
    {
      name: "Web Access",
      price: "$39",
    //   iconComponent: <Web />,
      benefits: [
        "Access to our Awesome Dashboard",
        "Access to Chatlogs",
        "Access to follow up feature",
        "Access to Human Interference"
       
      ],
      buttonText:"Choose Now"
    },
    {
      name: "Enterprise Plan",
      price: "Talk to our team",
      benefits: [
        "Unlock Tailored Solutions for Your Enterprise! Book a Demo to Explore Jaweb’s Custom Pricing Plans."
      ],
      buttonText: "Book Now"

    },
  ];
  