import React, { useState, useEffect } from 'react';
import { UserOutlined, LockOutlined, BellOutlined, WechatWorkOutlined, CreditCardOutlined, BookOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './components/MenuItems';
import 'antd/dist/reset.css';
import axios from 'axios';
import config from '../../config';


const CreditTransactionHistory = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const transactionHistoryTranslation = t('accountSetting')

  useEffect(() => {
    setLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
      'Accept': 'application/json',
    };

    axios.get(`${config.apiUrl}credits-transaction-history/`, { headers })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
        console.error(error);
      });
  }, []);

  const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  const shopify = UserInfo?.mode === 'shopify';

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatAmount = (amount) => { 
    return `$${amount}`;
  }

  const columns = [
    {
      title: transactionHistoryTranslation.transactionHistory.table.paymentAmt,
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (amount) => formatAmount(amount)
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.totalCredit,
      dataIndex: 'total_credit',
      key: 'total_credit',
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.creditType,
      dataIndex: 'credit_type',
      key: 'credit_type',
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.date,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => formatDate(text)
    },
  ];

  return (
    <CustomLayout>
      <div className="container">
        <Row gutter={[16, 16]} className="flex-container">
          <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption shopify={shopify} />
          </Col>
          <Col xs={24} sm={24} md={16} className="content">
            <div>
                <Table columns={columns} dataSource={data} loading={loading} />
            </div>
          </Col>
        </Row>
      </div>
    </CustomLayout>
  );
}

export default CreditTransactionHistory;
