import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip, Row, Col, Menu, message as antdMessage } from 'antd';
import { DeleteOutlined, CreditCardOutlined, UserOutlined, LockOutlined, BellOutlined,BookOutlined, DollarOutlined } from '@ant-design/icons';
import { Link, useLocation as useReactRouterLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import CustomLayout from '../../layouts/Customlayout';
import AddNewCardForm from '../components/AddNewCardForm';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const { Column } = Table;
const stripePromise = loadStripe('pk_live_51NXTEUCYSjtwtOyQma5bLqGdcFa1G7Rd3v2ThTaYAuo1nP6225YKgHRuBlJ7Sgx59j5s7ldWJfKE4xOq0YcicxAM00B2iT64vg');

const Billing = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(['card-1']);
  const [creditCards, setCreditCards] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const location = useReactRouterLocation();
  const stateMessage = location.state?.message;

  const { t } = useTranslation();
  const accSettingTranslations = t("accountSetting")

  useEffect(() => {
    if (stateMessage) {
      antdMessage.warning(stateMessage);
    }
    loadCards();
  }, [stateMessage]);

  const loadCards = async () => {
    try {
      const userdata = localStorage.getItem('Userdata');
      const userdataVarparsed = JSON.parse(userdata);
      const response = await fetch(`${config.apiUrl}cards/${localStorage.getItem('username')}/`, {
        headers: {
          Authorization: `Token ${userdataVarparsed?.token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setCreditCards(data.data);
    } catch (error) {
      console.error('Error loading cards:', error);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const addCard = async (values) => {
    const { cardHolderName, paymentMethodId } = values;
    try {
      const userdata = localStorage.getItem('Userdata');
      const userdataVarparsed = JSON.parse(userdata);
      const response = await fetch(`${config.apiUrl}add-card/${localStorage.getItem('username')}/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${userdataVarparsed?.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cardHolderName, token: paymentMethodId }),
      });

      if (response.ok) {
        antdMessage.success('Card added successfully');
        loadCards();
        setModalVisible(false);
      } else {
        const errorData = await response.json();
        console.error('Error adding card:', errorData.error);
        antdMessage.error('Error adding card');
      }
    } catch (error) {
      console.error('Error adding card:', error);
      antdMessage.error('Error adding card');
    }
  };

  const removeCard = async (cardId) => {
    try {
      const userdata = localStorage.getItem('Userdata');
      const userdataVarparsed = JSON.parse(userdata);
      const response = await fetch(`${config.apiUrl}remove-card/${localStorage.getItem('username')}/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${userdataVarparsed?.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ card_id: cardId }),
      });

      if (response.ok) {
        antdMessage.success('Card removed successfully');
        setCreditCards(creditCards.filter(card => card.id !== cardId));
      } else {
        const errorData = await response.json();
        console.error('Error removing card:', errorData.error);
        antdMessage.error('Error removing card');
      }
    } catch (error) {
      console.error('Error removing card:', error);
      antdMessage.error('Error removing card');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: onSelectChange,
  };

  const locale = {
    emptyText: (
      <div className="text-center my-4">
        <CreditCardOutlined />
        <h3 className="mt-3 font-weight-light">Please add a credit card!</h3>
      </div>
    ),
  };

  const MenuItem = ({ icon, path, label }) => (
    <>
      {icon}
      <span>{label}</span>
      <Link to={path} />
    </>
  );

  const SettingOption = () => {
    const locationPath = location.pathname.split('/');
    const currentPath = locationPath[locationPath.length - 1];

    return (
      <Menu
        mode="inline"
        selectedKeys={[currentPath]}
        items={[
          {
            key: 'edit-profile',
            label: <MenuItem label={accSettingTranslations.edit} icon={<UserOutlined />} path="/user/edit-profile" />,
          },
          {
            key: 'notification',
            label: <MenuItem label={accSettingTranslations.notification} icon={<BellOutlined />} path="/user/notification" />,
          },
          {
            key: 'cards',
            label: <MenuItem label={accSettingTranslations.billing} icon={<CreditCardOutlined />} path="/user/billing" />,
          },
          {
            key: 'plans',
            label: <MenuItem label={'Plans'} icon={<BookOutlined />} path="/user/plans" />,
          },
          {
            key: 'change-password',
            label: <MenuItem label={accSettingTranslations.changePass} icon={<LockOutlined />} path="/user/change-password" />,
          },
          {
            key: 'email-summary',
            label: <MenuItem label={'Email Summary'} icon={<BellOutlined />} path="/user/email-summary" />,
          },
          {
            key: 'transaction-history',
            label: <MenuItem label={'Transaction History'} icon={<DollarOutlined />} path="/user/transaction-history" />,
          }
        ]}
      />
    );
  };

  return (
    <CustomLayout>
      <div className='container'>
        <Row gutter={[16, 16]} className="flex-container">
          <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption />
          </Col>
          <Col xs={24} sm={24} md={16} className="content">
            <h2 className="mb-4">Billing</h2>
            <Table locale={locale} dataSource={creditCards} rowSelection={rowSelection} pagination={false}>
              <Column
                title={accSettingTranslations.cardType}
                key="cardType"
                render={(text, record) => (
                  <>
                    <span className="ml-2">{record.brand.toUpperCase()}</span>
                  </>
                )}
              />
              <Column title={accSettingTranslations.cardNo} dataIndex="last4" key="last4" render={text => `•••• •••• •••• ${text}`} />
              <Column title={accSettingTranslations.expire} dataIndex="exp_month" key="exp_month" render={(text, record) => `${text}/${record.exp_year}`} />
              <Column
                title={ accSettingTranslations.actions}
                key="actions"
                className="text-right"
                render={(text, record) => (
                  <Tooltip title="Remove card">
                    <Button
                      type="text"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => removeCard(record.id)}
                    />
                  </Tooltip>
                )}
              />
            </Table>
            <div className="mt-3 text-right">
              <Button type="primary" onClick={showModal}>{ accSettingTranslations.addNew}</Button>
            </div>
            <Elements stripe={stripePromise}>
              <AddNewCardForm visible={modalVisible} onCreate={addCard} onCancel={closeModal} />
            </Elements>
          </Col>
        </Row>
      </div>
    </CustomLayout>
  );
};

export default Billing;
