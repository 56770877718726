
import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../config';
import { Form, Input, message, Space, Button, Modal } from 'antd';
import { useStripe } from '@stripe/react-stripe-js';
import { UserOutlined, TeamOutlined, LockOutlined, MailOutlined, PhoneOutlined, CheckCircleOutlined } from '@ant-design/icons';
import bg from '../../../src/assets/svg/bg.png';

function SignUp() {
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [spin, setSpin] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpstatus, setOtpStatus] = useState('');
  const [form] = Form.useForm();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenEmail, setTokenEmail] = useState('');

  const stripe = useStripe();
  const inputRef = useRef();
  const navigate = useNavigate();
  const location = useLocation(); // To get the token from URL

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [confirmPassword, setPasswordConfirm] = useState('');
  const [error, setError] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Function to extract the token from the URL query parameter
  const getTokenFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token');
  };

  useEffect(() => {
    const token = getTokenFromUrl();
    if (token) {
      validateToken(token);
    }
  }, [location]);

  // Function to validate the token with the backend
  const validateToken = async (token) => {
    try {
      const response = await axios.post(`${config.apiUrl}validate-invitation-token/`, { token });

      if (response.status === 200 && response.data.email) {
        setIsTokenValid(true);
        setTokenEmail(response.data.email); // Pre-fill the email field
        setEmail(response.data.email);
      }
    } catch (error) {
      console.error('Token validation failed:', error);
      message.error('Token has expired or is invalid');
      setIsTokenValid(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordMismatch(e.target.value !== confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordMismatch(e.target.value !== password);
  };

  const generateRandomNumberString = (length) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };

  const handleSignUp = async () => {
    const verificationCodeUser = generateRandomNumberString(4);
    setError("");
    setSpin(true);
  
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    if (!isTokenValid) {
      formData.append('company_name', company);
    }
    formData.append('email', email); // Pre-filled if token is valid
    formData.append('otp', verificationCodeUser);
    // formData.append("team_id", generateRandomNumberString(10));
  
    try {
      const response = await axios.post(`${config.apiUrl}user/validate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Store user data in local storage
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      if (!isTokenValid) {
        localStorage.setItem('company_name', company);
      }
      if (isTokenValid) {
        formData.append('invitation', true);
      }
      localStorage.setItem('email', email);
      localStorage.setItem('otp', verificationCodeUser);
      // localStorage.setItem('team_id', generateRandomNumberString(10));
  
      message.success({ content: "Valid Information", duration: 4 });
  
      localStorage.setItem('signupFormData', JSON.stringify([...formData]));

      if (isTokenValid) {
        // If it's an invitation, redirect to the success page without payment
        navigate('/auth/payment/success');
      } else {
        // For normal signups, proceed with the Stripe payment
        setTimeout(async () => {
          try {
            const response = await fetch(`${config.apiUrl}payment/create-checkout/?user-name=${username}`);
            const data = await response.json();
  
            if (!response.ok) {
              throw new Error(data.message || 'Failed to create payment intent');
            }
  
            const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
            if (result.error) {
              throw new Error(result.error.message);
            }
          } catch (error) {
            setError(error.message);
          }
  
          setSpin(false);
        }, 3000);
      }
  
    } catch (error) {
      if (error.response) {
        message.error({ content: "Invalid Values", duration: 4 });
  
        const { errors } = error.response.data;
        if (errors) {
          if (errors.username) {
            setUsernameError(true);
          }
          if (errors.email) {
            setEmailError(true);
          }
        }
  
        setSpin(false);
      } else if (error.request) {
        console.error('No response received. Request:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const handleSendOtp = async () => {
    setOtp('');
    setOtpVerified(false);
    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);

    try {
      await form.validateFields();
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email, otp, type: 'signup' });

      if (response.status === 200) {
        message.success('OTP sent successfully');
        showModal();
        setOtpSent(true);
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }
  };

  const onChange = (text) => {
    if (text === generatedOtp) {
      setOtpVerified(true);
      setOtpStatus('success');
      handleSignUp();
    } else {
      setOtpStatus('error');
      setOtpVerified(false);
    }
  };

  const sharedProps = {
    onChange,
  };

  const handleLogin = () => {
    window.location.href = 'https://apps.shopify.com/jawebchatbot';
  };

  // Modify isFormComplete logic to exclude 'company' if token is valid
  const isFormComplete = username && email && password && confirmPassword && phone && !passwordMismatch && (isTokenValid || company);

  return (
    <div className="bg-[#F3E6FF] flex h-screen flex-1">
      <div className='md:block'>
        <img className='h-screen' src={bg} alt="Background" />
      </div>

      <div className='flex flex-col items-center justify-center flex-1 p-6 w-[100%]'>
        <div>
          <img className='h-[59.69px] w-[235.62px]' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb color logo.png' alt="Logo" />
        </div>

        <div className='w-[100%'>
          <span className='font-bold text-lg'>SIGN UP</span>
        </div>

        <Form
          name="Login"
          className="w-full relative flex flex-col justify-center items-center"
          layout='vertical'
          form={form}
          initialValues={{
            remember: true,
          }}
        >

          {!isTokenValid && (
            <Form.Item 
              name="company_name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Company Name',
                },
              ]}
              className="mb-2 w-[50%]"
            >
              <Input
                style={{ width: '100%' }}
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                prefix={<TeamOutlined />}
                required
              />
            </Form.Item>
          )}
        <Form.Item 
          name="email"
          label="Email"
          rules={[
            {
              required: !isTokenValid,  // Only require email input if token is not valid
              message: 'Please enter your email',
            },
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ]}
          className="mb-2 w-[50%]"
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              style={{ width: '100%' }}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<MailOutlined />}
              disabled={isTokenValid}  // Disable email input if token is valid
              required={!isTokenValid} // Make it required only when token is not valid
            />
          </Space.Compact>

          {emailError && <small id="emailError" className="text-red-500">Email is already connected to another account</small>}
        </Form.Item>

          <Form.Item 
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: 'Please enter your username',
              },
            ]}
            className="mb-2 w-[50%]"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              prefix={<UserOutlined />}
              required
            />
            {usernameError && <small id="usernameError" className="text-red-500">Username Already Taken</small>}
          </Form.Item>

          <Form.Item 
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
            ]}
            className="mb-2 w-[50%]"
          >
            <Input.Password
              style={{ width: '100%' }}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              prefix={<LockOutlined />}
              required
            />
          </Form.Item>

          <Form.Item 
            name="confirm_password"
            label="Re-enter Password"
            rules={[
              {
                required: true,
                message: 'Please re-enter your password',
              },
              {
                validator: (_, value) =>
                  value === password ? Promise.resolve() : Promise.reject(new Error('Passwords do not match.')),
              },
            ]}
            className="mb-2 w-[50%]"
          >
            <Input.Password
              style={{ width: '100%' }}
              placeholder="Re-enter Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              prefix={<LockOutlined />}
              required
            />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please enter your Phone Number',
              },
            ]}
            className="mb-2 w-[50%]"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              prefix={<PhoneOutlined />}
              required
            />
          </Form.Item>
          <div className="mt-2 w-[50%]">
            <Button 
              onClick={handleSendOtp} 
              disabled={!isFormComplete}
              loading={spin} 
              className="w-full px-36 py-4 flex items-center justify-center tracking-wide text-white transition-colors duration-200 transform bg-gray-900 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900" 
            >
              Sign Up
            </Button>

            <div className="relative flex items-center justify-center w-full mt-6 border border-t text-[#A7A7A7} px-2">
              <div className="absolute mx-6 text-[#A7A7A7]">Or</div>
            </div>

            <p className="mt-2 text-xs font-light text-center text-gray-700">
              Already Have an Account?{' '}
              <a className="font-medium text-purple-600 hover:underline" href="/">
                Login
              </a>
            </p>
          </div>

        </Form>

        <div className='flex items-center gap-4'>
          <button onClick={handleLogin} className="border flex gap-2 bg-white border-slate-200 rounded mt-2 p-2">
            <img className='w-4 h-4'  src='https://jawebstorage.us-iad-1.linodeobjects.com/images/shopify.svg'/>
            <span className='font-medium '>Sign in with Shopify</span>
          </button>

          <Modal title="Email Confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
            <div className='flex justify-center items-center p-2'>
              <span>Check Your Email For Authentication Code</span>
            </div>
            <div className='flex justify-center items-center p-2' >
              <Input.OTP status={otpstatus} value={otp} {...sharedProps}/>
            </div>
            <div className='flex justify-center items-center p-2'>
              {otpVerified ? <CheckCircleOutlined style={{color:'green'}}/> : <></>}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
